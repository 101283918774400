@keyframes fadeInModal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.introModal {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-width: 480px;
  background-color: #fff;
  position: absolute;
  width: 100%;
  overflow: hidden;
  border-radius: 20px;
  margin-bottom: 1rem;
  max-height: 98vh;
  max-height: 98dvh;
  width: 98vw;
  display: flex;
  outline: none;
  animation: fadeInModal 300ms forwards;
  opacity: 0;
}

.introOverlay {
  animation: fadeInModal 300ms forwards;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 0;
  z-index: 10;
}
